import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from "../models/user.model";
import 'rxjs/add/operator/map';
import { UrlComponent } from "../url.component";
import { JwtResponse } from '../auth/jwt-response';
import { Observable } from 'rxjs';
import { AuthLoginInfo } from '../auth/login-info';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl = UrlComponent.API_URL + '/api/auth/signin/';
  private signUpUrl = UrlComponent.API_URL + '/api/auth/signup/';
  private resetPasswordUrl = UrlComponent.API_URL + '/api/auth/generateOtp';
  private signupOTPUrl = UrlComponent.API_URL + '/api/auth/generateSignUpOtp';
  private forgotPasswordUrl = UrlComponent.API_URL + '/api/auth/validateOtp';
  private fakeApiCall = UrlComponent.API_URL + '/api/auth/fakeApiCall';

  private checkPinUrl = UrlComponent.API_URL + '/checkpin/';


  constructor(public httpp: Http, private http: HttpClient) { }

  attemptAuth(credentials: AuthLoginInfo): Observable<JwtResponse> {
    return this.http.post<JwtResponse>(this.loginUrl, credentials, httpOptions);
  }



  public logIn(user: User) {

    let headers = new Headers();
    headers.append('Accept', 'application/json')
    // creating base64 encoded String from user name and password
    var base64Credential: string = btoa(user.username + ':' + user.password);
    headers.append("Authorization", "Basic " + base64Credential);

    let options = new RequestOptions();
    options.headers = headers;

    return this.httpp.get(UrlComponent.API_URL + "/account/login", options)
      .map((response: Response) => {
        // login successful if there's a jwt token in the response
        let user = response.json().principal;// the returned user object is a principal object
        if (user) {
          // store user details  in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(user));
        }
      });
  }

  logOut() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    //Added above line for remove local Storage manually
    return this.http.post(UrlComponent.API_URL + "/account/logout", {})
      .map((response: Response) => {
        sessionStorage.removeItem('currentUser');
      });

  }


  login(credentials): Observable<any> {
    return this.http.post(this.loginUrl, {
      username: credentials.username,
      password: credentials.password
    }, httpOptions);
  }

  register(user): Observable<any> {
    //alert("-->"+user.username+user.email+user.password+user.mobileNo+user.firstName+user.lastName+user.signupOTP+user.acceptTNC)
    return this.http.post(this.signUpUrl, {
      username: user.username,
      email: user.email,
      password: user.password,
      mobileNo: user.mobileNo,
      firstName: user.firstName,
      lastName: user.lastName,
      otp: user.signupOTP,
      acceptTNC: user.acceptTNC
    }, httpOptions);
  }

  sendOTP(user): Observable<any> {
    const body = { username: user.username };
    return this.http.post<any>(this.resetPasswordUrl, body, httpOptions);
  }

  sendSignUpOTP(user): Observable<any> {
    //const body = { username: user.username };
    const body = { username: user.signupEmail };
    return this.http.post<any>(this.signupOTPUrl, body, httpOptions);
  }

  resetForgotPassword(user): Observable<any> {
    const body = JSON.stringify({ username: user.username, forgotOTP: user.forgotOTP, forgotNewPassword: user.forgotNewPassword });
    return this.http.post<any>(this.forgotPasswordUrl, body, httpOptions);
  }

  checkPinCode(pinCodes): Observable<any> {
    const body = JSON.stringify(pinCodes);
    return this.http.post(this.checkPinUrl + 'pincode', body, httpOptions);
  }

  sendfakedata(user): Observable<any> {
    return this.http.post<any>(this.fakeApiCall, {});
  }
}
