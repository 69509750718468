import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './url.component.html'
})

export class UrlComponent {
  //Develop
  //static API_URL = "http://localhost:8081/user-portal";

  //Used for prod
  static API_URL="https://craftsharbour.com:8443/user-portal";
  
  //Prod  Test
  //static API_URL="http://craftsharbour.com:8081/user-portal";   
  //static API_URL="http://148.251.252.226:8081/user-portal"; 
  //New Server
  //static API_URL="https://103.171.181.108:8443/user-portal";
}