import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlComponent } from "../url.component";
import { WishlistDetails } from '../models/WishlistDetails.model';
import { UserComments } from '../models/UserComments.model';
import { ProductDetails } from '../models/productDetails.model';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) { }
  private userUrlss = UrlComponent.API_URL + '/users';
  private wishListUrl = UrlComponent.API_URL + '/wishList/getWishList';
  private userCommentsUrl = UrlComponent.API_URL + '/userComment';
  private allUserCommentsUrl = UrlComponent.API_URL + '/userComment/getAllComments';
  private approveCommentsUrl = UrlComponent.API_URL + '/userComment/';
  private updateProfileUrl = UrlComponent.API_URL + '/profile/';
  private vendorOrderUrl = UrlComponent.API_URL + '/vendorProduct/getvendorProduct';
  private commentsUrl = UrlComponent.API_URL + '/userComment/';
  private deleteWishlistUrl = UrlComponent.API_URL + '/wishList/';

  private wishListToCartUrl = UrlComponent.API_URL + '/wishList/';

  public getWishList(username: string) {
    return this.http.get<WishlistDetails[]>(this.wishListUrl + "/" + username);
  }

  public getUserComments() {
    return this.http.get<UserComments[]>(this.userCommentsUrl);
  }

  public getAllUserComments() {
    return this.http.get<UserComments[]>(this.allUserCommentsUrl);
  }

  public deleteUser(wishlistDetails) {
    return this.http.delete(this.userUrlss + "/" + wishlistDetails.id);
  }

  approvedComments(usercomments: UserComments): Observable<any> {
    const body = JSON.stringify(usercomments);
    return this.http.post(this.approveCommentsUrl + 'approvedComment', body, httpOptions);
  }

  rejectedComments(usercomments: UserComments): Observable<any> {
    const body = JSON.stringify(usercomments);
    return this.http.post(this.approveCommentsUrl + 'rejectedComment', body, httpOptions);
  }

  public deleteFromCartPopup(usercomments: UserComments) {
    return this.http.delete(this.commentsUrl + "/" + usercomments.id);
  }

  public deleteFromWishlist(wishlists: WishlistDetails) {
    return this.http.delete(this.deleteWishlistUrl + "/" + wishlists.id);
  }

  addToCart(wishlists: WishlistDetails): Observable<any> {
    const body = JSON.stringify(wishlists);
    return this.http.post(this.wishListToCartUrl + 'addToCartFromWishList', body, httpOptions);
  }



  public updatePersonlDetails(personalDetails: Object) {
    return this.http.post(this.updateProfileUrl + 'updatePersonalDetails', personalDetails, httpOptions);

  }

  public updatePersonlAddressDetails(personalAddressDetails: Object) {
    return this.http.post(this.updateProfileUrl + 'updatePersonalAddressDetails', personalAddressDetails, httpOptions);

  }

  public getVendorProduct(username: string, productId: string) {
    return this.http.get<ProductDetails[]>(this.vendorOrderUrl + "/" + username + "/" + productId);
  }


  public currStockUpdate(username: string, productId: string, crrStock: boolean) {
    //const requestUrl = UrlComponent.API_URL + '/vendorProduct/currentStock/' + username.toString() + '/' + productId.toString() + '/' + crrStock;
    const requestUrl = UrlComponent.API_URL + '/vendorProduct/currentStock/' + productId.toString() + '/' + crrStock;
    const requestBody = JSON.stringify(crrStock);
    return this.http.post(requestUrl, requestBody, httpOptions);
  }
}

