import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { AuthService } from "../services/auth.service";
import { Observable } from 'rxjs';
import { ProductDetails, ProductResolved } from '../models/productDetails.model';
import { UserComments } from '../models/UserComments.model';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { TokenStorageService } from '../services/token-storage.service';
import { EventEmitterService } from '../services/event-emitter.service';
import { Location } from '@angular/common';



@Component({
  templateUrl: './displaylist.component.productDetails.html',
  styleUrls: ['./displaylist.component.productDetails.css']
})
export class DisplaylistProductDetails implements OnInit {

  CAROUSEL_BREAKPOINT = 768;
  carouselDisplayMode = 'multiple';
  errorMessage: string;
  successMessage: string;
  form: any = {};
  commentForm: any = {};
  element: HTMLElement;
  prodDetails: Observable<ProductDetails[]>;
  productFullDetails: ProductDetails[];
  userComment: Observable<UserComments[]>;
  userCommentLength: number;
  bestSellingProdDetails: Observable<ProductDetails[]>;
  selectedId: string;
  productId: Observable<string>;

  isPinCheckFailed = false;
  isPinCheckSuccess = false;

  reviewCount = 0;
  isLoggedIn = false;
  username: string;

  slides: any = [[]];
  slidesBestSelling2: any = [[]];
  slidesTwo: any = [[]];
  slideThree: any = [[]];

  fullProductDetails: ProductDetails = new ProductDetails();

  constructor(private router: Router, private authService: AuthService, private userService: UserService,
    private route: ActivatedRoute, private toastrService: ToastService, private tokenStorage: TokenStorageService,
    private eventEmitterService: EventEmitterService, private location: Location) { }
  fileUploads: Observable<string[]>


  firstComponentFunction() {
    this.eventEmitterService.onFirstComponentButtonClick();
  }

  loginComponentFunction() {
    this.eventEmitterService.loginComponentButtonClick();
  }

  ngOnInit() {

    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.username;
    }
    this.selectedId = atob(this.route.snapshot.queryParamMap.get('pvsu'));


    const resolvedData: ProductResolved =
      this.route.snapshot.data['resolvedData'];

    this.productFullDetails = resolvedData.productDetails;
    this.slidesTwo = this.chunkTwo(this.productFullDetails);
    
    //alert(this.selectedId);
//New Image4
    this.userService.getFourImageFiles(this.selectedId)
    .subscribe(data => {
      this.productFullDetails = data;
      this.slideThree = this.chunkTwo(this.productFullDetails);
    });


    this.userService.getUserComments(this.selectedId)
      .subscribe(data => {
        this.userComment = data;
        this.userCommentLength = data.length;
      });


    this.userService.getBestSellingProduct(this.selectedId)
      .subscribe(data => {
        this.bestSellingProdDetails = data;

        //used for populate best selling swipe
        this.slides = this.chunk(this.bestSellingProdDetails, 5);
        this.slidesBestSelling2 = this.chunk(this.bestSellingProdDetails, 2);
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
          this.carouselDisplayMode = 'single';
        } else {
          this.carouselDisplayMode = 'multiple';
        }
      });

      
  }



  addToCart(productDetail: ProductDetails) {
    const user = this.tokenStorage.getUser();
    productDetail.username = user.username;
    this.userService.addToCart(productDetail, this.form.pincode).subscribe(data => {
      console.log(data);
      this.eventEmitterService.onFirstComponentButtonClick();
      const options = { opacity: 1 };
      this.toastrService.success('Product added Successfully', '', options);
    }, err => {
      console.log(err);
      this.errorMessage = err.error.message;
      const options = { opacity: 1 };
      this.toastrService.error(this.errorMessage, '', options);
    }
    )
  }

  //Function used for same page only
  clickBestSelling(productId: String) {

    this.userService.getProductDetailsFiles(productId)
      .subscribe(data => {
        this.productFullDetails = data;
        this.slidesTwo = this.chunkTwo(this.productFullDetails);
      });

      //For Image4
      this.userService.getFourImageFiles(productId)
      .subscribe(data => {
        this.productFullDetails = data;
        this.slideThree = this.chunkTwo(this.productFullDetails);
      });

    this.userService.getUserComments(productId)
      .subscribe(data => {
        this.userComment = data;
      });


    this.userService.getBestSellingProduct(productId)
      .subscribe(data => {
        this.bestSellingProdDetails = data;
        this.slides = this.chunk(this.bestSellingProdDetails, 5);
        this.slidesBestSelling2 = this.chunk(this.bestSellingProdDetails, 2);
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
          this.carouselDisplayMode = 'single';
        } else {
          this.carouselDisplayMode = 'multiple';
        }

        window.scrollTo(0, 0);
      });

  };

  // slides: any = [[]];
  // slidesTwo: any = [[]];
  // slideThree: any = [[]];
  //slideThree: Array<string> = [];

  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  chunkTwo(arrs) {
    let RR = [];
    for (let person of arrs) {
      for (let personss of person.imageList) {
       // alert(personss);
        RR.push({ img: personss });
      }
    }
    return RR;
  }


  @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
      this.carouselDisplayMode = 'single';
    } else {
      this.carouselDisplayMode = 'multiple';
    }
  }


  projectsImages = [
    //{ img: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(135).jpg" },
    //{ img: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(128).jpg" },
    //{ img: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(133).jpg" },
    { img: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(131).jpg" }

  ];

  fetchBack(value): void {
    event.preventDefault();
    this.router.navigate(['/category'],
      {
        queryParams: {
          pvsu: btoa(value.substring(0, 3)),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });

    console.log(event);
  };

  fetchBackPage() {
    this.location.back();
  }

  checkPin(): void {
    this.authService.checkPinCode(this.form).subscribe(
      data => {
        console.log(data);
        this.isPinCheckFailed = false;
        this.isPinCheckSuccess = true;
        this.successMessage = data.message;
        const options = { opacity: 1 };
        this.toastrService.success(data.message, '', options);
      },
      err => {
        this.errorMessage = err.error.message;
        this.isPinCheckFailed = true;
        this.isPinCheckSuccess = false;
        const options = { opacity: 1 };
        this.toastrService.error(this.errorMessage, '', options);
      }
    );
  }


  buyNow(productDetail: ProductDetails) {
    const user = this.tokenStorage.getUser();
    productDetail.username = user.username;
    this.userService.addToCart(productDetail, this.form.pincode).subscribe(data => {
      console.log(data);
      this.router.navigate(['/orderplace', { buyNowId: productDetail.productId }]);
      this.eventEmitterService.onFirstComponentButtonClick();
    }, err => {
      console.log(err);
      this.errorMessage = err.error.message;
      const options = { opacity: 1 };
      this.toastrService.error(this.errorMessage, '', options);
    }
    )
  }

  onAddSubmit(productDetail: ProductDetails) {
    this.userService.addUserComments(this.commentForm, productDetail).subscribe(
      data => {
        console.log(data);
        this.element = document.getElementById('closeComments') as HTMLElement;
        this.element.click();
        const options = { opacity: 1 };
        this.toastrService.success('Thank you !', '', options);
      },
      err => {
        const options = { opacity: 1 };
        this.toastrService.error('Error! Try again', '', options);
      }
    );
  }


}
