import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UrlComponent } from "../url.component";
import { TransactionDetails } from '../models/TransactionDetails.model';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class OrderService {

  constructor(private http: HttpClient) { }

  private userUrlss = UrlComponent.API_URL + '/users';
  private currentOrderUrl = UrlComponent.API_URL + '/order/getCurrentOrder';
  private previousOrderUrl = UrlComponent.API_URL + '/order/getPreviousOrder';
  private cancelOrderUrl = UrlComponent.API_URL + '/order/cancelOrder';

  public getCurrentOrder(username: string): Observable<any> {
    return this.http.get<TransactionDetails[]>(this.currentOrderUrl + "/" + username);
  }

  public getPreviousOrder(username: string): Observable<any> {
    return this.http.get<TransactionDetails[]>(this.previousOrderUrl + "/" + username);
  }

  public deleteUser(transactionDetails) {
    return this.http.delete(this.userUrlss + "/" + transactionDetails.id);
  }

  public cancelOrder(txnId: string): Observable<any>{
    const params = new HttpParams()
      .set('TXNID', txnId);
      return this.http.get(this.cancelOrderUrl,{ params: params });
      }

     

}

