import {Observable} from 'rxjs/Observable';
export class ProductDetails {

  id: string;
  productId: string;
  productName: string;
  productDescription: string;
  productColor: string;
  emailId: string;
  productCost: number;
  discountCost: number;
  discountPercent: number;  
  status: string;
  imageId: string;
  rating: number;
  vendorCode: string;
  totalStock: number;
  currentStock: number;
  productLength: string;
  productType: string;
  productRegion: string;
  productFebric:string;
  imageFilePath: string;
  img:string;
  fileUploads: Observable<string[]>;
  username:string;
  pincode :string;
  newProduct: boolean;
  presentWishlist: boolean;
  presentInCart: boolean;
  includedComponent: string;
  masterColor: string;
  labelId: string;
  videoPath: string;
  productOrderPosition: number;
}

export interface ProductResolved {
  productDetails: ProductDetails[];
  error?: any;
}
