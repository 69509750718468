import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from './user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { UserCart, UserCartResolved } from '../models/UserCart.model';

@Injectable({
  providedIn: 'root'
})
export class UserCartResolver implements Resolve<UserCartResolved> {

  isLoggedIn = false;
  username: string;
  userCarts: UserCart[];

  constructor(private userService: UserService, private route: ActivatedRoute, private tokenStorage: TokenStorageService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<UserCartResolved> {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.username;
    }
    return this.userService.getShoppingCart(this.username)
      .pipe(
        map(usercart => ({ usercart: usercart })),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return of({ usercart: null, error: message });
        })
      );
  }

}
