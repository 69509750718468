import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
    
  invokeFirstComponentFunction = new EventEmitter();    
  invokeLoginComponentFunction = new EventEmitter(); 
  invokeMenuComponentFunction =  new EventEmitter(); 
  invokeProdDetailsComponentFunction = new EventEmitter();
  subsVar: Subscription;   
  //loginVar:  Subscription;   
    
  constructor() { }    
    
  onFirstComponentButtonClick() {    
    this.invokeFirstComponentFunction.emit();    
  } 
  
  loginComponentButtonClick() {    
    this.invokeLoginComponentFunction.emit();    
  }

  menuComponentButtonClick(id: string) {    
    this.invokeMenuComponentFunction.emit(id);    
  }

  productButtonClick(id: string) {    
    this.invokeProdDetailsComponentFunction.emit(id);    
  }
}    
