export class User {

  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  fullName: string;
  mobilenumber: string; 

}
