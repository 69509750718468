import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { UserService } from './user.service';
import { Observable } from 'rxjs/Observable';
import { OnInit } from '@angular/core';
import { ProductDetails, ProductResolved } from '../models/productDetails.model';


@Component({
  templateUrl: './add-user.component.html'
})
export class AddUserComponent implements OnInit {

  user: User = new User();
  public loading: boolean = false;
  bestSellingProdDetails: Observable<ProductDetails[]>;
  justAddedProdDetails: Observable<ProductDetails[]>;
  selectedId: string;
  CAROUSEL_BREAKPOINT = 768;
  carouselDisplayMode = 'multiple';
  imageWidth: number =102;
  imageMargin: number =1;
  imageWidthCategory: number =100;

  constructor(private router: Router, private userService: UserService) {

  }

  ngOnInit() {
    this.userService.getRecommondedItems()
    .subscribe(data => {
      this.bestSellingProdDetails = data;
      //used for populate best selling swipe
      this.slides = this.chunk(this.bestSellingProdDetails, 5);
      this.slidesTwo = this.chunk(this.bestSellingProdDetails, 2);
      if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
        this.carouselDisplayMode = 'single';
      } else {
        this.carouselDisplayMode = 'multiple';
      }
    });

    this.userService.getJustAddedItems()
    .subscribe(data => {
      this.justAddedProdDetails = data;
      //used for populate best selling swipe
      this.slidesJustAdded = this.chunk(this.justAddedProdDetails, 5);
      this.slidesTwoJustAdded = this.chunk(this.justAddedProdDetails, 2);
      if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
        this.carouselDisplayMode = 'single';
      } else {
        this.carouselDisplayMode = 'multiple';
      }
    });

  }

  fetchDetailss(value): void {
    event.preventDefault();
    this.router.navigate(['/category'],
      {
        queryParams: {
          pvsu: btoa(value),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });
    console.log(event);
  };

  createUser(): void {
    this.userService.createUser(this.user)
      .subscribe(data => {
      });

  };


  showFile = false
  fileUploads: Observable<string[]>
  showFiles(enable: boolean) {
    this.showFile = enable

    if (enable) {
      this.fileUploads = this.userService.getFiles();
    }
  }


  slides: any = [[]];
  slidesTwo: any = [[]];
  slidesJustAdded: any = [[]];
  slidesTwoJustAdded: any = [[]];
  slideThree: Array<string> = [];

  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  chunkTwo(arrs) {
    let RR = [];
    for (let person of arrs) {
      for (let personss of person.imageList) {
        RR.push({ img: personss });
      }
    }
    return RR;
  }

  fetchDetailsss(ProdDetails: ProductDetails): void {
    event.preventDefault();
    this.router.navigate(['/product'],
      {
        queryParams: {
          pvsu: btoa(ProdDetails.productId),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });
    console.log(event);
  };

  images = [
    { img: "./assets/img/Categories/baluchari.jpg", description: "Image 1" },
    { img: "./assets/img/Categories/swarnachari.jpg", description: "Image 2" },
    { img: "./assets/img/Categories/bishnipurSilk.jpg", description: "Image 3" },
    //{ img: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(150).jpg", thumb: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(150).jpg", description: "Image 2" },
    //{ img: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(152).jpg", thumb: "https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20(152).jpg", description: "Image 3" },    
  ];

}


