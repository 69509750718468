import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProductResolved } from '../models/productDetails.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsResolver implements Resolve<ProductResolved> {

  constructor(private userService: UserService, private route: ActivatedRoute) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<ProductResolved> {
    const id = atob(route.queryParams['pvsu']);
    return this.userService.getProductDetailsFiles(id)
      .pipe(
        map(productDetails => ({ productDetails: productDetails })),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return of({ productDetails: null, error: message });
        })
      );
  }

}
