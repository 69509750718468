import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UrlComponent } from "../url.component";
import { TransactionDetails } from '../models/TransactionDetails.model';
import { AdminOrderStatus } from '../models/adminOrderStatus';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class adminOrderService {

  constructor(private http: HttpClient) { }


  private userUrlss = UrlComponent.API_URL + '/users';
  private currentOrderUrl = UrlComponent.API_URL + '/order';
  private adminOrderUrl = UrlComponent.API_URL + '/order/adminOrderList';
  private updateAdminOrderStatus = UrlComponent.API_URL + '/order/';

  public getCurrentOrder() {
    return this.http.get<TransactionDetails[]>(this.currentOrderUrl);
  }

  public deleteUser(transactionDetails) {
    return this.http.delete(this.userUrlss + "/" + transactionDetails.id);
  }

  public getAdminOrder(orderStatus: string, fromDate: string, toDate: string): any {
    let params = new HttpParams().set("orderStatus", orderStatus).set("fromDate", fromDate).set("toDate", toDate); //Create new HttpParams
    return this.http.get<TransactionDetails[]>(this.adminOrderUrl, { params: params });
  }

  updateOrderStatus(orderStatus: AdminOrderStatus): Observable<any> {
    const body = JSON.stringify(orderStatus);
    return this.http.post(this.updateAdminOrderStatus + 'adminOrderStatusUpdae', body, httpOptions);
  }

}

