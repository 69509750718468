import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserCartComponent } from './user/usercart.component';
import { AddUserComponent } from './user/add-user.component';
import { DisplaylistSss } from './user/displaylist.componentSss';
import { DisplaylistProductDetails } from './user/displaylist.componentProductDetails';
import { PlaceOrder } from './PlaceOrder/placeOrder.component';
import { SelectiveStrategy } from './selective-strategy.service';
import { ProductResolver } from './user/displaylist-resolver.service';
import { ProductDetailsResolver } from './user/productdetails-resolver.service';
import { UserCartResolver } from './user/usercart-resolver.service';
import { PlaceOrderResolver } from './PlaceOrder/placeOrder-resolver.service';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'home', 
    pathMatch: 'full' 
  },
  
  {
    path: 'cart',
    component: UserCartComponent,
    resolve: { resolvedData: UserCartResolver }
  },

  { 
    path: 'home', 
    component: AddUserComponent 
  },

  {
    path: 'category',
    component: DisplaylistSss,
    resolve: { resolvedData: ProductResolver }
  },
  {
    path: 'product',
    component: DisplaylistProductDetails,
    resolve: { resolvedData: ProductDetailsResolver }
  },
  {
    path: 'orderplace',
    component: PlaceOrder,
    resolve: { resolvedData: PlaceOrderResolver }
  },
  {
    path: 'paymentStatus/:id',
    loadChildren: './Lazy/paymentStatus.module#PaymentStatusModule'
  },

  {
    path: 'wishList',
    loadChildren: './Lazy/wishlist.module#WishlistModule'
  },

  {
    path: 'currentOrderRouting',
    loadChildren: './Lazy/currentOrder.module#CurrentOrderModule'
  },

  {
    path: 'previousOrder',
    loadChildren: './Lazy/previousOrder.module#PreviousOrderModule'
  },

  {
    path: 'adminOrder',
    loadChildren: './Lazy/adminOrder.module#AdmiinOrderModule'
  },

  {
    path: 'adminProducts',
    loadChildren: './Lazy/adminProducts.module#AdminProductsModule'
  },

  {
    path: 'userReview',
    loadChildren: './Lazy/userReview.module#UserReviewModule'
  },

  {
    path: 'vendorProduct',
    loadChildren: './Lazy/vendorProduct.module#VendorProductModule'
  },

  {
    path: 'userprofile',
    loadChildren: './Lazy/myprofile.module#MyProfileModule'
  },

  {
    path: 'aboutUs',
    loadChildren: './LazyStaticPage/aboutUs.module#AboutUsModule'
  },

  {
    path: 'privacypolicy',
    loadChildren: './LazyStaticPage/privacyPolicy.module#PrivacyPolicyModule'
  },

  {
    path: 'termofuse',
    loadChildren: './LazyStaticPage/termOfUse.module#TermOfUseModule'
  },

  {
    path: 'cancelpolicy',
    loadChildren: './LazyStaticPage/cancelPolicy.module#CancelPolicyModule'
  },

  {
    path: 'helpfaqs',
    loadChildren: './LazyStaticPage/helpFaqs.module#HelpFaqsModule'
  },

  {
    path: 'craftsstory',
    loadChildren: './LazyStaticPage/craftsStory.module#CraftsStoryModule'
  },

  {
    path: 'refudCancel',
    loadChildren: './LazyStaticPage/refudCancel.module#RefundCancelModule'
  },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: SelectiveStrategy, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
