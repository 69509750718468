import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { PlaceOrderResolved, ShippingAddress } from '../models/ShippingAddress.model';

@Injectable({
  providedIn: 'root'
})
export class PlaceOrderResolver implements Resolve<PlaceOrderResolved> {

  isLoggedIn = false;
  username: string;
  shippingAddress: ShippingAddress;

  constructor(private userService: UserService, private route: ActivatedRoute,
    private tokenStorage: TokenStorageService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<PlaceOrderResolved> {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.username;
    }
    return this.userService.getShippigAddress(this.username)
      .pipe(
        map(shippingAddress => ({ shippingAddress: shippingAddress })),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return of({ shippingAddress: null, error: message });
        })
      );
  }

}
