export class placeOrder {
    productId: string;
    totalAmount: number;
    discountAmount: number;
    paybaleAmount: number;
    cratedBy: string;
    shippingFirstName: string;
    shippingLastName: string;
    shippingAddress: string;
    shippingCountry: string;
    shippingState: string;
    shippingPin: string;
    shippingMobileNo: string;
    txnType: string;
}