import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { ProductResolved } from '../models/productDetails.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProductResolver implements Resolve<ProductResolved> {


  minValue: number;
  maxValue: number;
  popminValue: number;
  popmaxValue: number;

  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.minValue = 500;
    this.maxValue = 25000;
    this.popminValue = 500;
    this.popmaxValue = 25000;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ProductResolved> {
    const id = atob(route.queryParams['pvsu']);
    return this.userService.getSssFiless(id, this.minValue, this.maxValue)
      .pipe(
        map(productDetails => ({ productDetails: productDetails })),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return of({ productDetails: null, error: message });
        })
      );
  }

}
