import { Component } from '@angular/core';

@Component({
  template: `
    <h1>This is not the page you were looking for!</h1>
    <li class="nav-item">
    <a class="nav-link" routerLink="/home">
      <mdb-icon fas icon="home"></mdb-icon>
    </a>
  </li>
    `
})
export class PageNotFoundComponent { }
