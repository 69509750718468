import { Injectable } from '@angular/core';
import { User } from "../models/user.model";
import { Http } from "@angular/http";
import { UrlComponent } from "../url.component"


@Injectable()
export class AccountService {

  cartCountss: number;
  constructor(public http: Http) { }

  createAccount(user: User) {
    return this.http.post(UrlComponent.API_URL + '/account/register', user)
      .map(resp => resp.json());
  }

  public getCartCount(email: String): any {
    return this.http.get(UrlComponent.API_URL + '/account/cartCount', { params: { emailId: email } }).map(data => {
      console.log("I CAN SEE DATA HERE: ", data.json());
      return data.json();
    });
  }

}

